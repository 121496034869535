import { Swiper, SwiperSlide } from "swiper/react";
import { RiSmartphoneLine, RiCodeBoxLine, RiPieChartBoxLine, RiBriefcase2Line, RiUser2Line, RiLinksLine } from "react-icons/ri";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { FreeMode, Pagination } from "swiper/modules";

const ServiceCard = ({ icon, title, description }) => (
    <motion.div
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col  mb-20 bg-secoundary_960 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[400px] w-full overflow-hidden cursor-pointer">
        <div className="absolute inset-0 bg-[#134555] opacity-10 group-hover:opacity-50" />
        <div className="relative flex flex-col gap-3">
            <div className="relative card px-8 py-16 rounded-xl">
                {icon}
                <h4 className='text-xl font-bold mb-3'>{title}</h4>
                <p className='font-light'>{description}</p>
            </div>
        </div>
    </motion.div>
);

const OurServices = () => {
    const services = [
        {
            icon: <RiSmartphoneLine className='text-5xl text-primary mb-4' />,
            title: 'تطوير الأنظمة والتطبيقات',
            description: 'نوفر أنظمة سحابية تشمل إدارة الحسابات والمخزون والموارد البشرية وغيرها.',
        },
        {
            icon: <RiCodeBoxLine className='text-5xl text-primary mb-4' />,
            title: 'تطوير المواقع الإلكترونية',
            description: 'بناء مواقع إلكترونية ديناميكية باستخدام أحدث التقنيات في التصميم والتطوير.',
        },
        {
            icon: <RiPieChartBoxLine className='text-5xl text-primary mb-4' />,
            title: 'تحليل البيانات والذكاء الاصطناعي',
            description: 'تطوير حلول تحليل البيانات باستخدام تقنيات الذكاء الاصطناعي لتحسين اتخاذ القرارات.',
        },
        {
            icon: <RiBriefcase2Line className='text-5xl text-primary mb-4' />,
            title: 'مشاريع متخصصة',
            description: 'نطور مشاريع تستهدف قاعدة واسعة من المستخدمين بمزايا مبتكرة.',
        },
        {
            icon: <RiUser2Line className='text-5xl text-primary mb-4' />,
            title: 'إدارة المشاريع التقنية',
            description: 'خدمات استشارية لإدارة مشاريع تقنية معقدة من البداية إلى النهاية.',
        },
        {
            icon: <RiLinksLine className='text-5xl text-primary mb-4' />,
            title: 'التكامل مع الأنظمة القائمة',
            description: 'تطوير حلول تكامل تربط بين الأنظمة القائمة وتحسن الأداء والتوافقية.',
        },
    ];

    return (
        <div id="our-services" className="flex items-center justify-center flex-col h-screen">
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className='text-center mb-8'
            >
                <h2 className='text-3xl font-semibold text-primary mb-5'>خدماتنا</h2>
                <p className='leading-7 mb-8'>أهم الخدمات التي نقدمها لعملائنا</p>
            </motion.div>

            <Swiper
                slidesPerView={1}
                spaceBetween={24}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                }}
                freeMode={true}
                pagination={{ clickable: true }}
                modules={[FreeMode, Pagination]}
                className="max-w-[90%] lg:max-w-[80%] "
            >
                {services.map((service, index) => (
                    <SwiperSlide key={index}>
                        <ServiceCard
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default OurServices;
