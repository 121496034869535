import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../assets/easylap-logo.svg';
import { FiPhone, FiAlignJustify, FiX } from "react-icons/fi";

const Navbar = () => {
    const [nav, setNav] = useState(true);
    const [isActive, setActive] = useState("home");

    const handleNav = () => {
        setNav(!nav);
    };

    return (

        <nav className='fixed w-full bg-secoundary top-0 z-40'>
            <div className='container max px-4 m-auto'>
                <div className='flex justify-between items-center h-24'>
                    <Link to="/#home">
                        <img src={Logo} width={'180px'} alt="شعار easylap" />
                    </Link>
                    <div className='hidden md:flex items-center'>
                        <Link to="/#home" className={`m-7 transition-colors ease-in duration-200 hover:text-primary ${isActive === 'home' ? 'text-primary' : 'text-primary_50'}`} onClick={() => setActive("home")}>
                            الرئسية
                        </Link>
                        <Link to="/#about" className={`m-7 transition-colors ease-in duration-200 hover:text-primary ${isActive === 'about' ? 'text-primary' : 'text-primary_50'}`} onClick={() => setActive("about")}>
                            من نحن
                        </Link>
                        <Link to="/#our-services" className={`m-7 transition-colors ease-in duration-200 hover:text-primary ${isActive === 'our-services' ? 'text-primary' : 'text-primary_50'}`} onClick={() => setActive("our-services")}>
                            خدماتنا
                        </Link>
                        <Link to="/#workpath" className={`m-7 transition-colors ease-in duration-200 hover:text-primary ${isActive === 'workpath' ? 'text-primary' : 'text-primary_50'}`} onClick={() => setActive("workpath")}>
                            المشاريع
                        </Link>
                        <Link to="/#contact" onClick={() => setActive("")} className='m-7 ml-0 px-4 py-2 flex gap-2 justify-center items-center rounded-md border-2 border-solid border-primary text-sm ease-in duration-300 hover:md:bg-primary hover:md:text-secoundary_960'>
                            <FiPhone />تواصل معنا
                        </Link>
                    </div>

                    {/* قائمة الجوال */}
                    <div onClick={handleNav} className='block md:hidden cursor-pointer transition-all ease-in-out duration-500'>
                        {!nav ? <FiX size={24} /> : <FiAlignJustify size={24} />}
                    </div>
                    <div className={!nav ? 'fixed top-0 right-0 bg-secoundary py-[50%] w-[70%] border-l border-primary_700 transition-all ease-in-out duration-300' : 'fixed right-[-100%]'}>
                        <ul className='justify-center pt-4 bg-secoundary h-screen z-50'>
                            <li className='mb-7 px-6 border-b border-primary_700 pb-2'>
                                <Link to="/#home" onClick={() => { setActive("home"); handleNav(); }} className="w-full block">الرئسية</Link>
                            </li>
                            <li className='mb-7 px-6 border-b border-primary_700 pb-2'>
                                <Link to="/#about" onClick={() => { setActive("about"); handleNav(); }} className="w-full block">من نحن</Link>
                            </li>
                            <li className='mb-7 px-6 border-b border-primary_700 pb-2'>
                                <Link to="/#our-services" onClick={() => { setActive("our-services"); handleNav(); }} className="w-full block">خدماتنا</Link>
                            </li>
                            <li className='mb-24 px-6 border-b border-primary_700 pb-2'>
                                <Link to="/#workpath" onClick={() => { setActive("workpath"); handleNav(); }} className="w-full block">المشاريع</Link>
                            </li>
                            <li className='m-4 px-4 py-2 flex gap-2 justify-center items-center rounded-md border-2 border-solid border-primary text-sm'>
                                <Link to="/#contact" onClick={handleNav}> <FiPhone />تواصل معنا</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
