import React from 'react';
import { motion } from "framer-motion";

const container = (delay) => ({
    hidden: { x: -100, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.5, delay: delay }
    }
})

const Hero = () => {
    return (
        <section className='hero overflow-hidden' id='home'>
            <div className='container h-screen m-auto px-4 md:pt-14 flex flex-col items-center justify-center md:flex-row text-center md:text-right'>
                <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 1.1 }}
                    className="md:w-[65%] mb-14 md:mb-0"
                >
                    <h1 className='text-5xl md:text-7xl lg:text-8xl lg:leading-[7rem] md:leading-[5rem] font-semibold mb-10 md:mb-0'>EsayLap <span className='text-primary mt-6 md:block'>لتطوير البرمجيات</span></h1>
                </motion.div>

                <div className="md:w-[35%] md:flex md:flex-col md:items-end">
                    <div>
                        <motion.h3
                            variants={container(0)}
                            initial='hidden'
                            animate='visible'
                            className='text-lg md:text-3xl md:leading-[2.8rem] font-semibold m-auto md:m-0 pb-4'
                        >
                            تحويل الأفكار إلى حقيقة:
                            <span className='block'>شركة برمجيات مبتكرة تلبي</span>
                            <span className=''>احتياجات العملاء</span>
                        </motion.h3>
                        <motion.p
                            variants={container(.5)}
                            initial='hidden'
                            animate='visible'
                            className='text-sm md:text-base m-auto md:m-0  md:leading-7 opacity-80'>تصميم وتطوير حلول برمجية مخصصة لتحقيق <span className='md:block'>النجاح في الأعمال</span>
                        </motion.p>
                        <motion.p
                            variants={container(1)}
                            initial='hidden'
                            animate='visible'
                            className='md:text-base text-primary underline underline-offset-4 hover:md:cursor-pointer hover:md:text-primary_400 mt-8 md:mt-6 p-0'>
                            استفد من خبراتنا وابدأ رحلة نجاحك البرمجية الآن
                        </motion.p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Hero