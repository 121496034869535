import React from 'react';
import { motion } from "framer-motion";
import Iconlogo from '../assets/logo-icon-v.svg';

const About = () => {
    return (
        <section className='container px-4 m-auto overflow-hidden' id='about'>
            <div className='flex flex-col-reverse md:flex-row justify-between items-center pb-20 md:py-32'>

                <div className="md:w-[80%]">
                    <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, }}
                    >
                        <span className='md:text-2xl text-primary'>من نحن</span>
                        <h4 className='text-2xl md:text-5xl md:leading-[4rem] font-semibold mt-2 mb-4 md:mb-6'><span className='block'>Easy Lab Technology CO.LTD</span>
                            شركة إيزي لاب تكنولوجي المحدودة
                        </h4>
                        <p >شركة إيزي لاب تعمل في مجال تقنية المعلومات وتقديم خدمات تصميم المواقع وتطوير الأنظمة السحابية وتطبيقات الجوال</p>
                    </motion.div>

                    {/* Vision and goals */}
                    <div className='flex flex-col md:flex-row mt-10 md:mt-14'>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: .2 }}
                        >
                            <h5 className='text-xl font-semibold mb-2 text-primary'>الرؤية والهدف</h5>
                            <p className='w-[80%] font-light md:leading-7'>هدفنا هو إثراء الساحة برمجيات وأنظمة تسهل وتنظم حياة الناس</p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >
                            <h5 className='text-xl font-semibold mb-2 mt-6 md:mt-0 text-primary'>الرسالة</h5>
                            <p>نحن نقدم الخدمات وفق ما يتطلبه الواقع ويحتاجه الناس</p>
                        </motion.div>
                    </div>
                </div>

                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: .5 }}
                    className="md:w-[16%] mb-10 md:mb-0 m-auto ">
                    <img src={Iconlogo} alt="icon logo" className='rotate-90 md:rotate-0 w-[80%] m-auto md:w-full' />
                </motion.div>
            </div>
        </section>
    )
}

export default About