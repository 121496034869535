import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import Loader from "./components/Loader";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Mudeer from "./components/projects/Mudeer";
import Rafig from "./components/projects/Rafig";
import Session from "./components/projects/Session";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [location.pathname]);

    return children;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Router>
            <Wrapper>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/mudeer" element={<Mudeer />} />
                <Route path="/rafig" element={<Rafig />} />
                <Route path="/session" element={<Session />} />
              </Routes>
            </Wrapper>
          </Router>
        </>
      )}
    </>
  );
}

export default App;
