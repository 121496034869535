import React from 'react';
import { FiSend } from "react-icons/fi";
import { motion } from "framer-motion";
import { RiPhoneLine } from "react-icons/ri";
import { RiMailLine } from "react-icons/ri";

const Contact = () => {
    return (
        <section id='contact' className="overflow-hidden">
            <div className='md:flex flex-row container px-10 m-auto py-16 bg-secoundary_960 rounded-2xl gap-8'>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className='flex flex-col justify-center text-cente mb-12 md:mb-0 md:text-right w-full md:w-2/4'
                >
                    <span className='text-base text-primary'>لأي سؤال أو استفسار</span>
                    <h2 className='text-4xl font-bold mt-2 mb-4'>تواصل معنا</h2>
                    <p className='leading-7 text-wrap md:w-[80%]'>يرجى توضيح طلبك أو مشكلتك بأكبر قدر ممكن، حتى نتمكن من مساعدتك بشكل أفضل.</p>
                    <div className='mt-10'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center  gap-2'>
                                <RiPhoneLine className='text-primary transform  scale-x-[-1] text-xl' />
                                <h6>رقم الهاتف</h6>
                            </div>
                            <p className='text-primary text-xl'>96877573609+</p>
                        </div>

                        <div className='flex flex-col gap-2 mt-8'>
                            <div className='flex items-center  gap-2'>
                                <RiMailLine className='text-primary transform  scale-x-[-1] text-xl' />
                                <h6>البريد الالكتروني</h6>
                            </div>
                            <p className='text-primary text-xl'>easylab.online@gmail.com</p>
                        </div>
                    </div>
                </motion.div>
                <motion.form
                    action="#"
                    method="POST"
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className='flex flex-col gap-6 md:w-[60%] md:mx-auto mt-10'
                >
                    <input type="text" name="name" id="name" required className='bg-white bg-opacity-95  py-3 px-3 md:px-6 rounded-md border-2 focus:outline-none focus:border-2 focus:border-primary_300 spacing-5 text-secoundary_960' placeholder='الاسم الكامل' />
                    <input type="email" name="email" id="email" required className='bg-white bg-opacity-95 py-3 px-3 md:px-6 rounded-md border-2 focus:outline-none text-secoundary_960 focus:border-primary_300' placeholder='البريد الالكتروني' />
                    <input type="text" name="location" id="location" className='bg-white bg-opacity-95 py-3 px-3 md:px-6 rounded-md border-2 focus:outline-none focus:border-primary_300 text-secoundary_960' placeholder='الدولة' />
                    <textarea name="message" id="message" cols="30" rows="10" required className='bg-white bg-opacity-95 py-4 px-3 md:px-6 rounded-md border-2 focus:outline-none focus:border-primary_300 text-secoundary_960' placeholder='الرسالة'></textarea>
                    <button type="submit" className='flex justify-center items-center py-4 px-3 mt-3 font-bold bg-primary hover:bg-primary_400 transition-colors rounded-md text-center text-secoundary_900'>
                        <FiSend className='text-xl ml-1' />
                        ارسال
                    </button>
                </motion.form>
            </div>
        </section>
    );
}

export default Contact;
