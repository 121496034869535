import React from 'react';
import Products from './Products';
import MudeerLogo from '../assets/mudeer-logo.svg';
import SessionApp from '../assets/session-app.svg';
import Rafiq from '../assets/rafig-logo.svg';
import { motion } from "framer-motion";

const WorkPaths = () => {
  const products = [
    {
      id: 1,
      name: "mudeer",
      proTitle: "نظام إدارة المدارس",
      proType: "نظام في المستقبل",
      proParagraph: "نظام مدير الإلكتروني الخاص بإدارة المدارس ولمدة ٨ سنوات نقوم بتطويره وإضافة المميزات له حتى أصبح متكاملا من نواحي إحتياجات المدارس وتحت مظلته أكثر من ٤٠ مؤسسة تعليمية",
      tools: ["PHP", "MySql", "JS", "HTML5", "Css3", "bootstrap"],
      img: MudeerLogo,
      bg: "#25626D"
    },
    {
      id: 2,
      name: "rafig",
      dir: "ltr",
      proTitle: "منصة الرفيق",
      proType: "نظام في المستقبل",
      proParagraph: "هو تقديم خدمات مباشرة للعملاء من تصميم للمواقع وتطوير تطبيقاتهم وكان تركيزنا على تطبيقات الجوال تلبية لرغبة السوق",
      tools: ["HTML5", "Css3", "React Native", "Node Js"],
      img: Rafiq,
    },
    {
      id: 3,
      name: "session",
      proTitle: "تطبيق جلسات",
      proType: "نظام في المستقبل",
      proParagraph: "هي منصة متخصصة في التواصل الرسمي والتعليمي تتيح غرف خاصة بعد الاجتماعات وغرف (خاصة كبديل لقاعات الدراسة الفيزيائية",
      tools: ["HTML5", "Css3", "React Native", "Node Js"],
      img: SessionApp,
    }
  ];

  return (
    <section id='workpath' className='overflow-hidden'>
      <div className="flex flex-col container px-4 md:px-0 m-auto py-20 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className='text-center mb-28'
        >
          <h2 className='text-3xl font-semibold text-primary mb-5'>المشاريع</h2>
          <p className='w-[90%] md:w-[60%] m-auto leading-8'>تنتهج ايزي لاب في تقديم خدماتها عبر ثلاث مسارات متوازية تطوير أنظمة سحابية وتقديمها للعملاء من المؤسسات أو الأفراد عبر الإنترنت باشتراكات سنوية أو شهرية تطوير تطبيقات تتبع للشركة لتقديم خدمات مباشرة للجمهور تطوير أنظمة وتطبيقات متخصصة حسب طلب العملاء من الشركات والأفراد</p>
        </motion.div>

        {products.map((product) => (
          <div key={product.id} className='mb-24 md:mb-40'>
            <Products
              id={product.id}
              name={product.name}
              dir={product.dir}
              proTitle={product.proTitle}
              proType={product.proType}
              proParagraph={product.proParagraph}
              tools={product.tools}
              img={product.img}
              imgWidth={product.imgWidth}
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default WorkPaths;
