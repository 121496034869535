import React from 'react'
import Hero from "../components/Hero";
import About from "../components/About";
import WorkPaths from "../components/WorkPaths";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Carousel from "../components/OurServices";

const Home = () => {
    return (
        <>
            <Hero />
            <About />
            <Carousel />
            <WorkPaths />
            <Contact />
            <Footer />
        </>
    )
}

export default Home
