import React from 'react'
import MudeerLogo from '../../assets/mudeer-logo.svg'
import MudeerTable from '../../assets/mudeer-table.png'
import MudeerFinancial from '../../assets/mudeer-financial.png'
import MudeerResult from '../../assets/mudeer-result.png'
import { RiWhatsappLine } from "react-icons/ri"
import { RiGlobalLine } from "react-icons/ri"
import { Link } from 'react-router-dom'



const Mudeer = () => {
    return (
        <div className='flex flex-col container justify-center items-center px-4 md:px-0 m-auto py-20 overflow-hidden'>
            <div className="flex flex-col justify-center items-center mt-10 mb-14 w-full rounded-2xl bg-white bg-opacity-5 max-w-[1520px] min-h-[416px] max-md:max-w-full">
                <div className="flex flex-col max-w-full w-[200px] md:w-[379px] ">
                    <img
                        loading="lazy"
                        src={MudeerLogo}
                        alt='Mudeer logo'
                        className="object-contain w-full aspect-[1.64] max-md:max-w-full"
                    />
                </div>
            </div>
            <div className='md:w-[80%]'>
                <p className='leading-8 mb-14'>نظام مدير هو نظام حاسوبي متكامل يعمل من خلال شبكة الانترنت. يساعد المؤسسة في حوسبة وإنجاز جميع المهام المالية والأكاديمية والإدارية اليومية. ويتكون من ثلاثة حزم</p>
                <div className='md:flex flex-row-reverse gap-8'>
                    <img
                        loading="lazy"
                        src={MudeerTable}
                        alt='Mudeer logo'
                        className="object-contain md:w-[50%] rounded-md mb-6"
                    />
                    <div className='md:w-[50%]'>
                        <div>
                            <h5 className='text-2xl text-primary mb-2'>حزمة جداول الحصص والبطاقات</h5>
                            <p className='leading-8'>هذا القسم يختصر الكثير من الوقت والجهد في عملية وضع جداول الحصص ويمنع الأخطاء وتضارب الحصص المدخلات المطلوبة<br />تحديد عدد الحصص وزمانها لكل أستاذ<br />تحديد عدد الحصص خلال اليوم لكل فصل<br />تحديد عدد الحصص خلال الاسبوع لكل مقرر</p>
                            <div>
                                <h6 className='text-primary mb-1 mt-4 text-lg'>مميزات :</h6>
                                <p className='leading-8'>
                                    يقوم النظام بتنبيهك في حال أردت وضع حصة لأستاذ في وقت هو مشغول فيه بحصة أخرى أو غير موجود بالمدرسة
                                    <br />
                                    يقدم النظام لك إحصائية دقيقة عن عدد الحصص التي وضعت
                                    <br />
                                    يقدم النظام لك إحصائية دقيقة لعدد الحصص لكل أستاذ أو مقرر خلال اليوم أو خلال الأسبوع
                                    <br />
                                    يمكنك النظام من طباعة نسخة من الجدول لكل فصل وموضح فيها إسم الأستاذ وإسم المقرر
                                    <br />
                                    يمكنك النظام من طباعة نسخة من الجدول لكل إستاذ وموضح فيها إسم المقرر وإسم الفصل
                                </p>
                            </div>
                        </div>
                        <div>
                            <h6 className='text-primary mb-1 mt-4'>طريقة العمل :</h6>
                            <p className='leading-8'>
                                الطريقة اليدوية :
                                <br />
                                تستطيع وضع الحصص لكل فصل حيث تختار موضع الحصة ومن ثم تختار المقرر والأستاذ
                                <br />
                                طريقة الوضع التلقائي :
                                <br />
                                في هذه الطريقة يقوم النظام نيابة عنك بتوزيع الحصص بطريقة عشوائية حسب المدخلات السابقة
                            </p>
                        </div>
                    </div>
                </div>

                <div className='md:flex gap-8 mt-10'>
                    <img
                        loading="lazy"
                        src={MudeerFinancial}
                        alt='Mudeer logo'
                        className="object-contain md:md:w-[50%] rounded-md mb-6"
                    />
                    <div className='md:w-[50%]'>
                        <h5 className='text-2xl text-primary mb-2'>حزمة الحسابات :</h5>
                        <p className='leading-8'>تنقسم الحزمة لثلاث أقسام :</p>
                        <div>
                            <h6 className='text-primary mb-1 mt-4 text-lg'>إدارة المنصرفات :</h6>
                            <p className='leading-8'>
                                يتيح لك النظام تسجيل كافة عمليات الصرف مع تقارير يومية أوشهرية أو سنوية لإجمالي قيمة المنصرفات ويمكنك أيضا من الحصول على تقرير مفصل على مصروفات معينة , فمثلاً يمكنك النظام من معرفة أجمالي قيمة شراء الطباشير لشهر أو لسنة
                            </p>
                        </div>
                        <div>
                            <h6 className='text-primary mb-1 mt-4 text-lg'>دارة عمليات تقسيط ودفع الرسوم:</h6>
                            <p className='leading-8'>
                                يمكنك النظام من تحديد أي عدد من الأقساط لكل طالب وتحديد تاريخ السداد لكل قسط يقوم النظام بتنبيهك بمواعيد دفع الأقساط في شكل ثلاثة أنواع من التنبيهات(الأقساط التي يجب دفعها اليوم - الأقساط الماضية التي لم تدفع - الأقساط التي يفترض دفعها يوم غد حيث يمكنك إستعراض الأقساط الواجبة السداد في شكل(إسم الطالب - رقم القسط - المبلغ المطلوب - رقم هاتف ولي الأمر) يمكنك النظام من طباعة خطابات المطالبة بالسداد الموجهة لأولياء الأمور لكل الأقساط التي حان دفعها بضغطة زر واحدة يمكنك النظام من معرفة نسبة المبلغ المدفوع من إجمالي الرسوم إستخراج تقارير لمعرفة نسبة الطلاب غير المكملين للرسوم يمكنك النظام من طباعة الإيصالات المالية تلقائياً عند دفع الأقساط يتحيح لك النظام عمل تقارير لمعرفة المبالغ المدفوعة والمبالغ المتبقية لطالب معين أو لفصل كامل أو لجميع الطلاب
                            </p>
                        </div>
                    </div>
                </div>

                <div className='mt-10 md:flex flex-row-reverse gap-8 items-center justify-center'>
                    <img
                        loading="lazy"
                        src={MudeerResult}
                        alt='Mudeer logo'
                        className="object-contain md:w-[50%] rounded-md mb-6"
                    />
                    <div className='md:w-[50%]'>
                        <h5 className='text-2xl text-primary mb-2'>حزمة النتيجة:</h5>
                        <p className='leading-8'>تتيح لك هذه الحزمة طباعة النتيجة لفصل كامل بضغطة زر واحدة</p>
                        <div>
                            <h6 className='text-primary mb-1 mt-4 text-lg'>خطوات إعداد النتيجة:</h6>
                            <p className='leading-8'>
                                إدخال النتيجة بواسطة الأساتذة أو بواسطة الشخص المسؤول ، حيث يحتوي النظام علي شاشة سهلة الإستخدام لإدخال النتيجةيتم إدخال الدرجات لفصل كامل مقرر تلو الآخريمكن تدوين الملاحظات علي نتيجة الطالب في مقرر معين لعرضها علي مرشد الصف للتقيم ووضع الملاحظة النهائية أو لعمل تقرير مفصّل لولي أمر الطالبيستطيع مرشد الفصل عرض النتيجة كاملة طالب تلو الآخر لتدوين الملاحظات النهائية عليهايمكنك النظام من طباعة نتيجة فصل كامل أو طالب محدد أو مجموعة من الطلاب بضغطة زر واحدةيقوم النظام بحساب النسبة المئوية والتقدير للطالب وطباعتها على النتيجة تلقائياً وبدون تدخل حسابي يدويتظل النتيجة محفوظة في قاعدة البيانات بعد إنتهاء السنة الدراسية ، مع إمكانية الرجوع إليها في أي وقت حتى بعد عدة أعوام                        </p>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>

                    <h5 className='text-2xl text-primary mb-2'>مميزات إضافية للنظام:</h5>
                    <ul className='flex flex-col gap-3 list-disc pr-6'>
                        <li>
                            العمل من خلال شبكة الإنترنت، مما يسمح بالدخول إلى النظام من أي مكان وفي أي وقت.
                        </li>
                        <li>
                            إمكانية عرض النتائج والموقف المالي للطالب عبر الموقع الإلكتروني.
                        </li>
                        <li>
                            ربط فروع متعددة للمدرسة ومعرفة التقارير التفصيلية لكل فرع.
                        </li>
                        <li>
                            الدعم الفني السريع مع تقديم التعديلات ومعالجة الأخطاء بسرعة.
                        </li>
                    </ul>
                </div>
            </div>

            <div className='md:flex gap-16 mt-20'>
                <div className='mb-10'>
                    <h4 className='text-lg mb-4'>للطلب والايتفسار</h4>
                    <div className='flex gap-1 justify-center items-center'>
                        <RiWhatsappLine className='text-lg text-primary' />
                        <span>+96877573609</span>
                    </div>
                </div>
                <div>
                    <h4 className='text-lg mb-4'>تجربة النظام اون لاين</h4>
                    <div className='flex gap-1 justify-center items-center'>
                        <RiGlobalLine className='text-lg text-primary' />
                        <Link className='border-b-2 cursor-pointer hover:border-b-primary'>www.mudeer.com</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mudeer





