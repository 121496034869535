import React from 'react';
import { FiExternalLink } from "react-icons/fi";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const Products = (props) => {


    return (
        <div className={`group md:relative flex flex-col ${props.dir ? 'md:flex-row' : 'md:flex-row-reverse'} md:justify-between md:items-stretch`}>
            <motion.div
                initial={props.dir ? { opacity: 0, x: 100 } : { opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className='relative flex items-center justify-center    h-[300px] md:h-[450px] w-[100%] md:w-[55%] rounded-md overflow-hidden'
            >
                <img
                    src={props.img}
                    alt={props.proTitle}
                    className={`${props.imgWidth ? 'w-full' : 'w-[40%]'} opacity-45 group-hover:opacity-90`}
                    loading="lazy"
                />
                <div className="absolute inset-0 bg-primary group-hover:opacity-20 opacity-45 rounded-md transition-all delay-75"></div>
            </motion.div>
            <motion.div
                initial={props.dir ? { opacity: 0, x: -100 } : { opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
                className={`flex flex-col justify-between py-8 gap-6 md:gap-0 ${props.dir ? 'md:text-left' : 'md:text-right'}`}
            >
                <div>
                    <span className='text-primary text-sm font-bold'>{props.proType}</span>
                    <h4 className='text-3xl'>{props.proTitle}</h4>
                </div>
                <div className={`md:absolute md:top-[50%] md:translate-y-[-50%] ${props.dir ? 'md:left-[0]' : 'md:right-[0]'} flex flex-col justify-center md:items-center py-6 px-4 md:p-0 md:z-[1] h-[55%] md:w-[750px] text-base bg-secoundary_960 rounded-md`}>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className='w-[100%] md:w-[70%] leading-8'
                    >
                        {props.proParagraph}
                    </motion.div>
                </div>
                <div className={`flex flex-col ${props.dir ? 'md:items-end' : 'md:items-start'}`}>
                    <ul className='flex flex-wrap md:flex-row-reverse mb-4 gap-6'>
                        {props.tools.map((tool, index) => (
                            <li key={index}>{tool}</li>
                        ))}
                    </ul>
                    <Link to={`/${props.name}`} className='cursor-pointer flex md:justify-center md:items-center gap-2 text-primary md:text-white  group-hover:text-primary transition-all delay-75'>
                        <FiExternalLink className='text-xl ' />
                        تفاصيل المشروع
                    </Link>
                </div>
            </motion.div>
        </div>
    );
}

export default Products;
