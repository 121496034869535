import React from 'react'
import rafigLogo from '../../assets/rafig-logo.svg'
import rafigApp from '../../assets/rafig-app.png'

const Rafig = () => {
    return (
        <div className='flex flex-col container justify-center items-center px-4 md:px-0 m-auto py-20 overflow-hidden'>
            <div className="flex flex-col justify-center items-center mt-10 mb-14 w-full rounded-2xl bg-white bg-opacity-5 max-w-[1520px] min-h-[416px] max-md:max-w-full">
                <div className="flex flex-col max-w-full w-[200px] md:w-[379px] ">
                    <img
                        loading="lazy"
                        src={rafigLogo}
                        alt='Ragig logo'
                        className="object-contain w-full aspect-[1.64] max-md:max-w-full"
                    />
                </div>
            </div>

            <div className='md:w-[80%] md:flex flex-row-reverse gap-8'>
                <img
                    loading="lazy"
                    src={rafigApp}
                    alt='Rafig logo'
                    className="object-contain md:w-[50%] rounded-md mb-6"
                />
                <div className='w-[90%] md:w-[50%]'>
                    <p className='leading-8 mb-10'>الرفيق هو عبارة عن تطبيق يحتوي علي العديد من الخدمات التي تساعد في توفير الوقت والمجهود مثل التواصل مع الاصدقاء والتسوق والترجمة وغيرها</p>
                    <div className='mt-10'>
                        <h5 className='text-2xl text-primary mb-2'>قسم تعلم اللغات</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقوم المستخدم بإدخال جملة أو نص، ويتم تحليل الجملة لاكتشاف أي أخطاء لغوية أو نحوية.
                            </li>
                            <li>
                                يقوم النظام بتصحيح الأخطاء، مع تقديم الشرح لتوضيح سبب التصحيح.
                            </li>
                            <li>
                                يتم ترجمة الجملة إلى اللغة المستهدفة، مما يساعد على تعلم مفردات جديدة.
                            </li>
                        </ul>

                        <h5 className='text-xl mt-4  mb-2'>خدمة محادثة تفاعلية</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقوم المستخدم باختيار موضوع معين من قائمة مواضيع متاحة (مثل السياحة أو العمل).
                            </li>
                            <li>
                                يجري "الرفيق" محادثة مع المستخدم، مع تصحيح الأخطاء وتقديم ردود مترجمة.
                            </li>
                        </ul>
                    </div>

                    <div className='mt-10'>
                        <h5 className='text-2xl text-primary mb-2'>قسم الترجمة الفورية</h5>
                        <h5 className='text-xl  mb-2'>ترجم بنظرة</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يستخدم المستخدم الكاميرا لالتقاط صورة تحتوي على نص (مثل لافتة أو وثيقة).
                            </li>
                            <li>
                                يقوم النظام بتحليل النص وترجمته فورًا إلى اللغة المستهدفة.
                            </li>
                        </ul>

                        <h5 className='text-xl mt-4  mb-2'>الترجمة النصية</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقوم المستخدم بإدخال جملة أو فقرة نصية، ويتم ترجمتها فورًا إلى اللغة المطلوبة.
                            </li>
                        </ul>
                    </div>

                    <div className='mt-10'>
                        <h5 className='text-2xl text-primary mb-2'>قسم مراجعة الترجمة</h5>
                        <h5 className='text-xl  mb-2'>خدمة مراجعة الترجمة</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقوم المستخدم بإدخال نص مترجم مع النص الأصلي.
                            </li>
                            <li>
                                يقوم النظام بتحليل الترجمة، واكتشاف الأخطاء وتصحيحها.
                            </li>
                        </ul>
                    </div>

                    <div className='mt-10'>
                        <h5 className='text-2xl text-primary mb-2'>قسم تقييم النص</h5>
                        <h5 className='text-xl  mb-2'>خدمة تقييم النص</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقوم المستخدم بإدخال نص بأي لغة.
                            </li>
                            <li>
                                يختار المستخدم لغة التقييم، ويقوم النظام بتحليل النص لاكتشاف الأخطاء.
                            </li>
                            <li>
                                يتم عرض الأخطاء مع التصحيحات بنفس اللغة المختارة.
                            </li>
                        </ul>

                        <h5 className='text-xl mt-4  mb-2'>اقتراحات تحسين النص</h5>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                يقدم النظام اقتراحات لتحسين صياغة النص، مثل تحسين تنسيق الجمل واختيار كلمات أفضل.                        </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='flex gap-16 mt-16'>
                <h4 className='text-2xl mb-4'>سوف يتم إطلاق التطبيق <span className='text-primary'>قريبا</span> </h4>
            </div>
        </div>
    )
}

export default Rafig
