import React from 'react'
import SessionLogo from '../../assets/session-app.svg'
import SessionOnboarding from '../../assets/session-onboarding.png'
import SessionChat from '../../assets/session-chat.png'
import SessionScreens from '../../assets/session-screens.png'

const Session = () => {
    return (
        <div className='flex flex-col container justify-center items-center px-4 md:px-0 m-auto py-20 overflow-hidden'>
            <div className="flex flex-col justify-center items-center mt-10 mb-14 w-full rounded-2xl bg-white bg-opacity-5 max-w-[1520px] min-h-[416px] max-md:max-w-full">
                <div className="flex flex-col max-w-full w-[200px] md:w-[379px] ">
                    <img
                        loading="lazy"
                        src={SessionLogo}
                        alt='Session logo'
                        className="object-contain w-full aspect-[1.64] max-md:max-w-full"
                    />
                </div>
            </div>
            <div className='md:w-[80%]'>
                <p className='leading-8 mb-10'>تطبيق session هو عبارة عن تطبيق تواصل يتح انشاء جلسات للتعليم ومناقشة المشاريع</p>

                <div className='md:flex flex-row-reverse justify-center items-center gap-8 mt-10'>
                    <img
                        loading="lazy"
                        src={SessionOnboarding}
                        alt='Mudeer logo'
                        className="object-contain md:w-[50%] rounded-md mb-6"
                    />

                    <div>
                        <h6 className='text-2xl text-primary mb-2'>مميازت التطبيق</h6>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                توفير بيئة اجتماعات ومحاضرات بأدوات تحكم مرنه وسهلة الاستخدام
                            </li>
                            <li>
                                توفير خصوصية للمشتركي
                            </li>
                            <li>
                                الاشتراك مع المعاهد والأساتذة والمدارس لعمل دروس ومحاضرات مخصصة للتطبيق
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='md:flex flex- justify-center items-center gap-8 mt-10'>
                    <img
                        loading="lazy"
                        src={SessionOnboarding}
                        alt='Mudeer logo'
                        className="object-contain md:w-[50%] rounded-md mb-6"
                    />

                    <div>
                        <h6 className='text-2xl text-primary mb-2'>مميازت التطبيق</h6>
                        <ul className='flex flex-col gap-3 list-disc pr-6'>
                            <li>
                                توفير بيئة اجتماعات ومحاضرات بأدوات تحكم مرنه وسهلة الاستخدام
                            </li>
                            <li>
                                توفير خصوصية للمشتركي
                            </li>
                            <li>
                                الاشتراك مع المعاهد والأساتذة والمدارس لعمل دروس ومحاضرات مخصصة للتطبيق
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex gap-16 mt-16'>
                <h4 className='text-2xl mb-4'>سوف يتم إطلاق التطبيق <span className='text-primary'>قريبا</span> </h4>
            </div>
        </div>
    )
}

export default Session