import React from 'react';
import { FiFacebook } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FiGithub } from "react-icons/fi";
import { motion } from "framer-motion";

const Footer = () => {
    return (
        <footer className="bg-bg text-center text-white overflow-hidden mt-14">
            <motion.div

                className="container m-auto pt-9"
            >
                <div className="mb-9 flex justify-center">
                    <a href="https://www.facebook.com" aria-label="Facebook" className="text-white">
                        <FiFacebook className='text-2xl hover:cursor-pointer hover:text-primary_400' />
                    </a>
                    <a href="https://www.facebook.com" aria-label="Facebook" className="mr-9 text-white hover:cursor-pointer hover:text-primary_400">
                        <FiLinkedin className='text-2xl' />
                    </a>
                    <a href="https://www.facebook.com" aria-label="Facebook" className="mr-9 text-white">
                        <FiTwitter className='text-2xl hover:cursor-pointer hover:text-primary_400' />
                    </a>
                    <a href="https://www.facebook.com" aria-label="Facebook" className="mr-9 text-white">
                        <FiInstagram className='text-2xl hover:cursor-pointer hover:text-primary_400' />
                    </a>
                    <a href="https://www.facebook.com" aria-label="Facebook" className="mr-9 text-white">
                        <FiGithub className='text-2xl hover:cursor-pointer hover:text-primary_400' />
                    </a>
                </div>
            </motion.div>

            {/* <!--Copyright section--> */}
            <motion.div

                className="bg-secoundary_960 p-4 text-center">
                © 2024 Copyright:
                <a
                    className="text-white "
                    href="https://tw-elements.com/"
                >Esaylap</a>
            </motion.div>
        </footer>
    )
}

export default Footer

